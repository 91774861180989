function SfuminoApp() {
  return (
    <div className="app">
      <header className="app__header">
        <div className="app__container">
          <h1 className="app__title">Sfumino Studio</h1>
        </div>
      </header>
      <main className="app__main">
        <div className="app__container">
          <figure className="app__logo">
            <img src="/assets/logo-web-1.png" alt="Sfumino Studio" />
          </figure>
        </div>
      </main>
    </div>
  );
}

export default SfuminoApp;
